<template>
  <router-view id="addin" />
  <SimpleModal v-if="!organizationName">
    <VerticalStack
      alignment="center"
      class="p-4"
    >
      <p>
        {{ $t('screens.addin.noOrganizationInfo.info') }}
      </p>
      <default-button
        @click="createOrganization"
      >
        {{ $t('screens.addin.noOrganizationInfo.button') }}
      </default-button>
      <default-button
        type="constructive"
        @click="organizationCreated"
      >
        {{ $t('screens.addin.noOrganizationInfo.reload') }}
      </default-button>
      <LogoutButtonComponent />
    </VerticalStack>
  </SimpleModal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import LogoutButtonComponent from '@/components/user/LogoutButtonComponent.vue';
import MainLogoComponent from '@/components/viewelements/main/MainLogoComponent.vue';
import HorizontalStack from '@/components/layout/HorizontalStack.vue';
import StackSpacer from '@/components/layout/StackSpacer.vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import { useStore } from 'vuex';
import { Organization } from '@/types/Organization';
import BottomNavigationBar from '@/components/controls/BottomNavigationBar.vue';
import MainMenuComponent from '@/components/viewelements/main/MainMenuComponent.vue';
import SimpleModal from '@/components/layout/SimpleModal.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import User from '@/types/User';
import { openExternalUrl } from '@/utils/powerpoint-helper';
import MeService from '@/services/MeService';
import useLoadingState from '@/composables/use-loading-state';
import initializeCookieAlert from '@/composables/use-cookie-alert';

export default defineComponent({
  components: {
    DefaultButton,
    SimpleModal,
    MainMenuComponent,
    BottomNavigationBar,
    LogoutButtonComponent,
    MainLogoComponent,
    HorizontalStack,
    StackSpacer,
    VerticalStack,
  },
  setup() {
    const store = useStore();
    const { startLoading, stopLoading } = useLoadingState();

    const organizationName = computed(() => {
      return (store.getters.user as User)?.organizationName || '';
    });

    initializeCookieAlert();

    const createOrganization = () => {
      startLoading();
      MeService.getOneTimeToken()
        .then((token) => {
          const url = `https://${window.location.host}/admin`;
          openExternalUrl(url, token);
        })
        .finally(() => {
          stopLoading();
        });
    };

    const organizationCreated = () => {
      window.location.reload();
    };

    return {
      organizationName,
      createOrganization,
      organizationCreated,
    };
  },
});
</script>
