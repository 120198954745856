import { createApp } from 'vue';
import store from '@/store/store';
import { i18n } from '@/i18n/i18n-setup';
import './axios-setup';
import LabeledDefaultInput from '@/components/controls/LabeledDefaultInput.vue';
import CardElement from '@/components/CardElement.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import DefaultInput from '@/components/controls/DefaultInput.vue';
import './styles/main.css';
import MessageComponent from '@/components/MessageComponent.vue';
import App from './App.vue';
import { createAddinRouter, createWebRouter } from './router';
import HorizontalStack from '@/components/layout/HorizontalStack.vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import StackSpacer from '@/components/layout/StackSpacer.vue';
import permissionPlugin from '@/plugins/permission-plugin';
import ModalView from '@/components/layout/ModalView.vue';
import userPlugin from '@/plugins/user-plugin';
import SimpleDivider from '@/components/viewelements/SimpleDivider.vue';
import LabeledDivider from '@/components/viewelements/LabeledDivider.vue';
import {
  checkPowerPointModal,
  inOfficeAddin,
  isPowerPointModal,
  setInAddin,
  setPowerPoint
} from '@/office-checker';
import FileInput from '@/components/controls/FileInput.vue';
import VueLazyLoad from 'vue3-lazy';
import WrappingFlow from '@/components/layout/WrappingFlow.vue';
import { LocalSettingsService } from '@/services/LocalSettingsService';
import ManageContentCategoryTreeItemView from '@/views/admin/files/ManageContentCategoryTreeItemView.vue';
import { v5 as uuidv5 } from 'uuid';
import vueDompurifyHTMLPlugin from 'vue-dompurify-html';
import DOMPurify from 'dompurify';
import { PERMISSIONS } from '@/permissions';

setInAddin((new URLSearchParams(window.location.search)).has('_host_Info'));

console.log(window.location.pathname);

function isUUID(str: string): boolean {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(str);
}

function generateCustomFingerprint() {
  const navigatorInfo = window.navigator;
  const screenInfo = window.screen;
  const randomString = Math.random().toString(36).substring(2, 15);
  let fingerprint = '';

  fingerprint += `userAgent:${navigatorInfo.userAgent}`;
  fingerprint += `|platform:${navigatorInfo.platform}`;
  fingerprint += `|language:${navigatorInfo.language}`;
  fingerprint += `|screenResolution:${screenInfo.width}x${screenInfo.height}`;
  fingerprint += `|colorDepth:${screenInfo.colorDepth}`;
  fingerprint += `|randomString:${randomString}`;

  return uuidv5(fingerprint, uuidv5.URL);
}

async function initializeApp() {
  const existingIdentifier = await LocalSettingsService.getUniqueClientIdentifier();

  if (!existingIdentifier || !isUUID(existingIdentifier)) {
    const visitorId = generateCustomFingerprint();
    await LocalSettingsService.setUniqueClientIdentifier(visitorId);
  }

  const router = await (inOfficeAddin() ? createAddinRouter() : createWebRouter());

  const app = createApp(App)
    .use(router)
    .use(VueLazyLoad, { })
    .use(vueDompurifyHTMLPlugin)
    .use(i18n)
    .use(store)
    .use(permissionPlugin, { store })
    .use(userPlugin, { store })
    .component('message-component', MessageComponent)
    .component('MessageComponent', MessageComponent)
    .component('labeled-default-input', LabeledDefaultInput)
    .component('LabeledDefaultInput', LabeledDefaultInput)
    .component('card-element', CardElement)
    .component('CardElement', CardElement)
    .component('default-button', DefaultButton)
    .component('DefaultButton', DefaultButton)
    .component('default-input', DefaultInput)
    .component('DefaultInput', DefaultInput)
    .component('HorizontalStack', HorizontalStack)
    .component('VerticalStack', VerticalStack)
    .component('StackSpacer', StackSpacer)
    .component('modal-view', ModalView)
    .component('ModalView', ModalView)
    .component('simple-divider', SimpleDivider)
    .component('SimpleDivider', SimpleDivider)
    .component('labeled-divider', LabeledDivider)
    .component('LabeledDivider', LabeledDivider)
    .component('file-input', FileInput)
    .component('FileInput', FileInput)
    .component('WrappingFlow', WrappingFlow)
    .component('wrapping-flow', WrappingFlow)
    .component('ManageContentCategoryTreeItemView', ManageContentCategoryTreeItemView)
    .component('manage-content-category-tree-item-view', ManageContentCategoryTreeItemView);

  app.config.globalProperties.$PERMISSIONS = PERMISSIONS;

  app.directive('safe-html', {
    beforeMount(el, binding) {
      el.innerHTML = DOMPurify.sanitize(binding.value);
    },
    updated(el, binding) {
      el.innerHTML = DOMPurify.sanitize(binding.value);
    },
  });

  app.directive('allowed', {
    beforeMount(el, binding) {
      const permissions = Array.isArray(binding.value) ? binding.value : [binding.value];
      const instance = binding.instance;

      if (!!instance) {
        const isAllowed = permissions.some(permissionName => instance.$userAllowed(permissionName));

        if (!isAllowed) {
          el.style.display = 'none';
        }
      } else {
        el.style.display = 'none';
      }
    },
    updated(el, binding) {
      const permissions = Array.isArray(binding.value) ? binding.value : [binding.value];
      const instance = binding.instance;

      if (!!instance) {
        const isAllowed = permissions.some(permissionName => instance.$userAllowed(permissionName));

        if (isAllowed) {
          el.style.display = '';
        } else {
          el.style.display = 'none';
        }
      } else {
        el.style.display = 'none';
      }
    },
  });


  app.mount('#app')
}

if (inOfficeAddin()) {
  const script = document.createElement('script');
  script.src = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js';

  script.onload = () => {
    try {
      Office.onReady((info) => {
        checkPowerPointModal();
        OfficeExtension.config.extendedErrorLogging = true;

        setPowerPoint(info.host === Office.HostType.PowerPoint);
        const url = require('@/styles/deactivate-scrollbars.css');
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        document.head.appendChild(link);
        initializeApp();
      });
    } catch (_) {
      initializeApp();
    }
  };
  document.head.appendChild(script);
} else {
  initializeApp();
}
