import { AxiosError } from 'axios';

function isAxiosError(error: any): error is AxiosError {
  return (error as AxiosError).isAxiosError;
}

function isAxiosErrorWithCode(error: any, code: number | number[]): error is AxiosError {
  if (!isAxiosError(error)) {
    return false;
  }

  const errorCodes = Array.isArray(code) ? code : [code];
  return errorCodes.includes(error.response?.status ?? 0);
}

export { isAxiosError, isAxiosErrorWithCode };
