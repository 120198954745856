import OrganizationInvitation from '@/types/OrganizationInvitation';
import axios from 'axios';
import { api } from '@/config';

class InvitationService {
  static async getInvitation(reference: string): Promise<OrganizationInvitation> {
    return (await axios.get<OrganizationInvitation>(`${api.v1}/me/invitations/${reference}`)).data;
  }

  static async acceptInvitation(reference: string): Promise<void> {
    await axios.patch(`${api.v1}/me/invitations/${reference}/accept`);
  }

  static async rejectInvitation(reference: string): Promise<void> {
    await axios.patch(`${api.v1}/me/invitations/${reference}/reject`);
  }

  static async withdrawInvitation(reference: string): Promise<void> {
    await axios.patch(`${api.v1}/invitations/${reference}/withdraw`);
  }

  static setInvitationInformation(email: string, reference: string): void {
    sessionStorage.setItem(
      'invitationInformation',
      JSON.stringify({
        email,
        reference,
      })
    );
  }

  static getInvitationInformation(): { email: string; reference: string } | null {
    const invitationInformation = sessionStorage.getItem('invitationInformation');

    if (!invitationInformation) {
      return null;
    }

    return JSON.parse(invitationInformation);
  }

  static deleteInvitationInformation(): void {
    sessionStorage.removeItem('invitationInformation');
  }
}

export default InvitationService;
