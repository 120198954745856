import { useStore } from 'vuex';
import { watch } from 'vue';
import ConsentsService from '@/services/ConsentsService';
import useAlert from '@/composables/use-alert';
import { useRoute } from 'vue-router';
import { isPowerPoint } from '@/office-checker';

const initializeCookieAlert = () => {
  const store = useStore();
  const { showAlert, createAlertButton } = useAlert();
  const route = useRoute();

  watch(() => store.getters.user, (user) => {
    if (user && (user.organizationSlug || !isPowerPoint)) {
      if (user.trackingConsent === null && !route.path.includes('/register/')) {
        showAlert({
          title: 'screens.main.cookieConsentAlert.title',
          type: isPowerPoint ? 'normal' : 'bottom',
          message: 'screens.main.cookieConsentAlert.message',
          buttons: [
            createAlertButton({
              title: 'screens.main.cookieConsentAlert.accept',
              type: 'primary',
              action: async () => {
                await ConsentsService.enableTracking();
              }
            }),
            createAlertButton({
              title: 'screens.main.cookieConsentAlert.reject',
              type: 'link',
              action: async () => {
                await ConsentsService.disableTracking();
              }
            }),
          ]
        });
      } else {
        if (user.trackingConsent === true) {
          ConsentsService.enableAppInsightsTracking(user);
        } else {
          ConsentsService.disableAppInsightsTracking();
        }
      }
    } else {
      ConsentsService.disableAppInsightsTracking();
    }
  }, { immediate: true });
}

export default initializeCookieAlert;
