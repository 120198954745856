interface PageResult<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;

  sort(compareFn?: (a: T, b: T) => number): void

  sortBy<Key extends keyof T>(key: Key, ascending: boolean): void

}

interface Identifiable {
  id: number;
}

class SortablePageResult<T extends Identifiable> implements PageResult<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;

  constructor(pageResult: PageResult<T>) {
    this.content = pageResult.content;
    this.totalPages = pageResult.totalPages;
    this.totalElements = pageResult.totalElements;
    this.last = pageResult.last;
    this.first = pageResult.first;
    this.numberOfElements = pageResult.numberOfElements;
    this.size = pageResult.size;
    this.number = pageResult.number;
  }

  sort(compareFn?: (a: T, b: T) => number): void {
    this.content.sort(compareFn);
  }

  sortBy<Key extends keyof T>(key: Key, ascending: boolean = true): void {
    this.content.sort((a, b) => {
      if (a[key] < b[key]) return ascending ? -1 : 1;
      if (a[key] > b[key]) return ascending ? 1 : -1;
      return 0;
    });
  }
}

class EmptyPageResult<T> implements PageResult<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;

  constructor() {
    this.content = [];
    this.totalPages = 0;
    this.totalElements = 0;
    this.last = true;
    this.first = true;
    this.numberOfElements = 0;
    this.size = 0;
    this.number = 0;
  }

  sort(compareFn?: (a: T, b: T) => number): void {
    // do nothing
  }

  sortBy<Key extends keyof T>(key: Key, ascending: boolean = true): void {
    // do nothing
  }

  static empty<Type = any>(): PageResult<Type> {
    return new EmptyPageResult<Type>();
  }
}

function replacePagedItem<T extends Identifiable>(pageResult: PageResult<T>, newItem: T): void {
  const index = pageResult.content.findIndex(item => item.id === newItem.id);
  if (index !== -1) {
    pageResult.content[index] = newItem;
  }
}

export { PageResult, SortablePageResult, EmptyPageResult, replacePagedItem };
