let isPowerPoint = false;
let isPowerPointModal = false;

const checkPowerPointModal = () => {
  // isPowerPointModal = true;
  isPowerPointModal = typeof Office !== 'undefined' &&
    Office.context &&
    Office.context.ui &&
    typeof Office.context.ui.messageParent === 'function';
}

const setPowerPoint = (value: boolean) => {
  isPowerPoint = value && !isPowerPointModal;
}

const inOfficeAddin = () => {
  setInAddin((new URLSearchParams(window.location.search)).has('_host_Info'));
  return localStorage.getItem('inOfficeAddin') === 'true';
};

const setInAddin = (value: boolean) => {
  if (value) {
    localStorage.setItem('inOfficeAddin', value.toString());
  }
}

export {
  inOfficeAddin,
  isPowerPoint,
  isPowerPointModal,
  setPowerPoint,
  checkPowerPointModal,
  setInAddin,
};
