import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CodeInput = _resolveComponent("CodeInput")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_auth_view_holder = _resolveComponent("auth-view-holder")!

  return (_openBlock(), _createBlock(_component_auth_view_holder, {
    title: _ctx.t('screens.registration.emailVerification.title')
  }, {
    default: _withCtx(() => [
      (!_ctx.isPowerPoint())
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.verify && _ctx.verify(...args)), ["prevent"]))
          }, [
            _createVNode(_component_VerticalStack, {
              alignment: "center",
              spacing: 6.0
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('screens.registration.emailVerification.text')), 1),
                _createVNode(_component_CodeInput, {
                  modelValue: _ctx.code,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
                  error: _ctx.onceSent && _ctx.code.length < 6
                }, null, 8, ["modelValue", "error"]),
                _createVNode(_component_default_button, {
                  actionType: "submit",
                  label: _ctx.t('screens.registration.emailVerification.buttons.verify'),
                  class: "w-full mt-8",
                  "auto-loading-state": "global"
                }, null, 8, ["label"]),
                _createVNode(_component_default_button, {
                  disabled: _ctx.resendDisabled,
                  type: "link",
                  label: _ctx.resendLabel,
                  onClick: _ctx.resendCode,
                  class: "w-full mt-2",
                  "auto-loading-state": "global"
                }, null, 8, ["disabled", "label", "onClick"])
              ]),
              _: 1
            })
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}