export const PERMISSIONS = {
  "USER_READ_OTHERS": "USER_READ_OTHERS",
  "USER_EDIT_OTHERS": "USER_EDIT_OTHERS",
  "USER_ACTIVE_LOGINS_EDIT_OTHERS": "USER_ACTIVE_LOGINS_EDIT_OTHERS",
  "USER_ACTIVE_LOGINS_READ_OTHERS": "USER_ACTIVE_LOGINS_READ_OTHERS",
  "USER_READ_SELF": "USER_READ_SELF",
  "USER_EDIT_SELF": "USER_EDIT_SELF",
  "USER_ACTIVE_LOGINS_EDIT_SELF": "USER_ACTIVE_LOGINS_EDIT_SELF",
  "USER_ACTIVE_LOGINS_READ_SELF": "USER_ACTIVE_LOGINS_READ_SELF",
  "USER_CHANGE_AUTHENTICATION": "USER_CHANGE_AUTHENTICATION",
  "USER_DEACTIVATE_TWOFA": "USER_DEACTIVATE_TWOFA",
  "ORGANIZATION_CREATE": "ORGANIZATION_CREATE",
  "ORGANIZATION_READ": "ORGANIZATION_READ",
  "ORGANIZATION_EDIT": "ORGANIZATION_EDIT",
  "ORGANIZATION_USERS_EDIT": "ORGANIZATION_USERS_EDIT",
  "ORGANIZATION_USERS_INVITE": "ORGANIZATION_USERS_INVITE",
  "ORGANIZATION_USERS_REMOVE": "ORGANIZATION_USERS_REMOVE",
  "ORGANIZATION_USERS_CHANGE_ROLE": "ORGANIZATION_USERS_CHANGE_ROLE",
  "ORGANIZATION_AZURESYNC_EDIT": "ORGANIZATION_AZURESYNC_EDIT",
  "ORGANIZATION_USERS_READ": "ORGANIZATION_USERS_READ",
  "ORGANIZATION_AZURESYNC_READ": "ORGANIZATION_AZURESYNC_READ",
  "LICENSE_READ": "LICENSE_READ",
  "LICENSE_EDIT": "LICENSE_EDIT",
  "LICENSE_MANAGE_SEATS": "LICENSE_MANAGE_SEATS",
  "LICENSE_MANAGE_SUBSCRIPTION": "LICENSE_MANAGE_SUBSCRIPTION",
  "CONTENT_READ": "CONTENT_READ",
  "CONTENT_EDIT": "CONTENT_EDIT",
  "CONTENT_FAVORITE_READ": "CONTENT_FAVORITE_READ",
  "CONTENT_FAVORITE_EDIT": "CONTENT_FAVORITE_EDIT"
} as const;

export type Permission = typeof PERMISSIONS[keyof typeof PERMISSIONS];
