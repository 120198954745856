<template>
  <MenuItem
    :padded="false"
    icon="/icons/menu.svg"
  >
    <template #submenu>
      <VerticalStack :spacing="2" class="p-4">
        <span class="whitespace-nowrap">{{ userName }}</span>
        <span class="text-sm text-foreground-light">{{ userMail }}</span>
      </VerticalStack>
      <simple-divider class="px-4 w-full" />
      <MenuItem
        @click="openProfile"
        :exact="false"
        permissions="USER_READ_SELF"
        class="w-full"
        icon="/icons/user.svg"
        :label="$t('screens.main.buttons.profile')"
      />
      <SimpleDivider class="w-full" v-allowed="PERMISSIONS.ORGANIZATION_EDIT" />
      <LogoutButtonComponent class="mt-4" />
      <VersionInfo class="pt-4" />
    </template>
  </MenuItem>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import MenuItem from '@/components/controls/MenuItem.vue';
import SimpleDivider from '@/components/viewelements/SimpleDivider.vue';
import LogoutButtonComponent from '@/components/user/LogoutButtonComponent.vue';
import { useStore } from 'vuex';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import StackSpacer from '@/components/layout/StackSpacer.vue';
import AlertDialog from '@/components/AlertDialog.vue';
import VersionInfo from '@/components/viewelements/VersionInfo.vue';
import { openExternalUrl } from '@/utils/powerpoint-helper';
import { PERMISSIONS } from '@/permissions';
import useLoadingState from '@/composables/use-loading-state';
import MeService from '@/services/MeService';

export default defineComponent({
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    }
  },
  components: {
    VersionInfo,
    AlertDialog,
    StackSpacer, VerticalStack, LogoutButtonComponent, SimpleDivider, MenuItem},
  setup() {

    const { startLoading, stopLoading } = useLoadingState();
    const store = useStore();
    const userName = computed(() => {
      const user = store.getters.user;
      return user ? `${user.firstName} ${user.lastName}` : '';
    });
    const userMail = computed(() => {
      const user = store.getters.user;
      return user ? user.email : '';
    });

    const openProfile = () => {
      startLoading();
      MeService.getOneTimeToken()
        .then((token) => {
          const url = `https://${window.location.host}/admin/me`;
          openExternalUrl(url, token);
        })
        .finally(() => {
          stopLoading();
        });
    }

    return {
      userName,
      openProfile,
      userMail,
    };
  },
});
</script>
