<template>
  <auth-view-holder
    :title="t('screens.registration.data.title')"
  >
    <form @submit.prevent="register">
      <template v-if="showCredentialsLogin">
        <VerticalStack alignment="center">
          <labeled-default-input
            class="w-full"
            :label="$t('screens.registration.data.firstName.label')"
            v-model="firstName"
            type="text"
            :error="onceSent && firstName === ''" />
          <labeled-default-input
            class="w-full"
            :label="$t('screens.registration.data.lastName.label')"
            v-model="lastName"
            type="text"
            :error="onceSent && lastName === ''" />
          <labeled-default-input
            class="w-full"
            :label="$t('screens.registration.data.email.label')"
            v-model="email"
            type="email"
            :disabled="emailDisabled"
            :error="onceSent && email === ''" />
          <labeled-default-input
            class="w-full"
            :label="$t('screens.registration.data.password.placeholder')"
            v-model="password"
            type="password"
            :error="onceSent && password === '' || passwordRulesNotFit || passwordsDoNotMatch"
            :info="$t('screens.registration.data.password.info')"
          />
          <labeled-default-input
            class="w-full"
            :label="$t('screens.registration.data.passwordConfirmation.label')"
            v-model="passwordConfirmation"
            type="password"
            :error="onceSent && passwordConfirmation === '' || passwordsDoNotMatch" />
          <div id="recaptcha-holder" />
        </VerticalStack>
      </template>
      <img v-if="!showCredentialsLogin"
           src="/icons/microsoft.svg"
           class="w-20 h-20 mx-auto mt-6"
      />
      <VerticalStack class="mt-6">
        <default-checkbox
          :label="$t('screens.registration.data.termsAndPrivacyCheckbox')"
          size="large"
          v-model="termsAndPrivacyAccepted"
        />
        <default-checkbox
          :label="$t('screens.registration.data.acceptMailingCheckbox')"
          size="large"
          v-model="acceptMailingAccepted"
        />
      </VerticalStack>
      <default-button
        v-if="showCredentialsLogin"
        actionType="submit"
        :label="t('screens.registration.data.buttons.register')"
        class="w-full mt-6"
        auto-loading-state="global"
      />
      <default-button
        :label="$t('screens.registration.data.buttons.registerWithMicrosoft')"
        class="w-full mt-6"
        auto-loading-state="global"
        @click="registerWithMicrosoft"
      />
      <p class="pt-4 text-foreground-light text-center">
        {{
          $t('screens.registration.data.microsoftRegisterHint')
        }}
      </p>
      <VerticalStack class="w-full mt-6" alignment="center">
        <default-button
          type="link"
          v-if="!showCredentialsLogin"
          @click="showCredentialsLogin = true"
        >
          {{ $t('screens.registration.data.buttons.useCredentials') }}
        </default-button>
        <default-button to-route="/login" auto-loading-state="global" v-if="!isPowerPointModal()">
          {{ $t('screens.registration.data.links.login') }}
        </default-button>
      </VerticalStack>
    </form>
  </auth-view-holder>

</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import AuthService from '@/services/AuthService';
import { isAxiosError } from 'axios';
import AuthViewHolder from '@/components/layout/AuthViewHolder.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import useMessages from '@/composables/use-messages';
import { useRoute, useRouter } from 'vue-router';
import { loadRecaptcha } from '@/utils/recaptcha-helper';
import useLoadingState from '@/composables/use-loading-state';
import DefaultCheckbox from '@/components/controls/DefaultCheckbox.vue';
import InvitationService from '@/services/InvitationService';
import PaymentService, { TierInterval, TierQuantity } from '@/services/PaymentService';
import paymentService from '@/services/PaymentService';
import { isPowerPointModal } from '@/office-checker';

export default defineComponent({
  name: 'LoginView',
  methods: {
    isPowerPointModal() {
      return isPowerPointModal
    }
  },
  components: {
    DefaultCheckbox,
    VerticalStack,
    DefaultButton,
    AuthViewHolder,
  },
  setup() {
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const password = ref('');
    const passwordConfirmation = ref('');
    const passwordRulesNotFit = ref(false);
    const passwordsDoNotMatch = ref(false);
    const recaptchaToken = ref('');
    const showCredentialsLogin = ref(false);
    const termsAndPrivacyAccepted = ref(false);
    const acceptMailingAccepted = ref(false);

    const emailDisabled = ref(false);

    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const { startLoading, stopLoading } = useLoadingState();
    const store = useStore();

    const onceSent = ref(false);

    let loadedPasswordRulesPromise: Promise<string> | null = null;

    const { setErrorMessage } = useMessages();

    watch(showCredentialsLogin, async (newValue) => {
      if (newValue) {
        await nextTick();
        initializeRecaptcha();
      }
    });

    const register = async () => {
      onceSent.value = true;
      if (
        firstName.value === ''
        || lastName.value === ''
        || email.value === ''
        || password.value === ''
        || passwordConfirmation.value === ''
      ) {
        setErrorMessage(t('screens.registration.data.errors.emptyFields'));
        return;
      }

      if (password.value !== passwordConfirmation.value) {
        setErrorMessage(t('screens.registration.data.errors.passwordMismatch'));
        passwordsDoNotMatch.value = true;
        return;
      }

      passwordsDoNotMatch.value = false;

      if (!recaptchaToken.value) {
        setErrorMessage(t('screens.registration.data.errors.captcha'));
        return;
      }

      if (!termsAndPrivacyAccepted.value) {
        setErrorMessage(t('screens.registration.data.errors.needAcceptTerms'));
        return;
      }

      const rules = await loadedPasswordRulesPromise;

      if (!rules) {
        setErrorMessage(t('errors.unknown'));
        return;
      }

      if (!AuthService.checkPassword(password.value, rules)) {
        setErrorMessage(t('screens.registration.data.errors.passwordRules'));
        passwordRulesNotFit.value = true;
        return;
      }

      passwordRulesNotFit.value = false;

      await startLoading();

      AuthService.register(
        email.value,
        password.value,
        firstName.value,
        lastName.value,
        recaptchaToken.value,
        acceptMailingAccepted.value,
        router
      )
        .catch((error) => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(t('screens.registration.data.errors.emailTaken'));
          } else if (isAxiosError(error) && error.response?.status === 400) {
            setErrorMessage(t('screens.registration.data.errors.captchaServer'));
            initializeRecaptcha();
          } else {
            setErrorMessage(t('errors.unknown'));
          }
        }).finally( async () => {
          await stopLoading();
        });
    };

    onMounted(() => {
      loadedPasswordRulesPromise = AuthService.getPasswordRules();

      email.value = route.query.email as string || '';
      if (route.query.invitation) {
        emailDisabled.value = true;
        InvitationService.setInvitationInformation(route.query.email as string, route.query.invitation as string);
      }

      handlePlanPreselection();
    });

    const handlePlanPreselection = () => {
      if (route.query.plan && route.query.interval && route.query.seats) {
        const seatsAsNumber = Number(route.query.seats);
        if (isNaN(seatsAsNumber)) {
          return;
        }
        paymentService.storeUserPlanSelection(
          route.query.plan as string,
          seatsAsNumber as TierQuantity,
          route.query.interval as string as TierInterval
        );
      }
    }

    const initializeRecaptcha = () => {
      loadRecaptcha(
        'recaptcha-holder',
        (token) => recaptchaToken.value = token,
        () => {
          recaptchaToken.value = '';
          setErrorMessage('screens.registration.data.errors.captchaTimeout');
        },
        () => {
          recaptchaToken.value = '';
          setErrorMessage('screens.registration.data.errors.captchaClient');
        }
      );
    };

    const registerWithMicrosoft = async () => {

      if (!termsAndPrivacyAccepted.value) {
        setErrorMessage(t('screens.registration.data.errors.needAcceptTerms'));
        return;
      }

      try {
        await AuthService.startAuthenticationWithMicrosoft('signup', acceptMailingAccepted.value, false);
      } catch (error) {
        setErrorMessage(t('screens.login.errors.microsoftLoginFailed', { error }));
      }
    };

    return {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      register,
      t,
      onceSent,
      passwordRulesNotFit,
      passwordsDoNotMatch,
      showCredentialsLogin,
      registerWithMicrosoft,
      termsAndPrivacyAccepted,
      acceptMailingAccepted,
      emailDisabled,
    };
  },
});
</script>
