<template>
  <auth-view-holder
    :title="t('screens.registration.emailVerification.title')"
  >
    <form @submit.prevent="verify" v-if="!isPowerPoint()">
      <VerticalStack alignment="center" :spacing="6.0">
        <p>{{ $t('screens.registration.emailVerification.text') }}</p>
        <CodeInput
          v-model="code"
          :error="onceSent && code.length < 6"
        />
        <default-button
          actionType="submit"
          :label="t('screens.registration.emailVerification.buttons.verify')"
          class="w-full mt-8"
          auto-loading-state="global"
        />
        <default-button
          :disabled="resendDisabled"
          type="link"
          :label="resendLabel"
          :onClick="resendCode"
          class="w-full mt-2"
          auto-loading-state="global"
        />
      </VerticalStack>
    </form>
  </auth-view-holder>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import MeService from '@/services/MeService';
import { isAxiosError } from 'axios';
import { useI18n } from 'vue-i18n';
import AuthViewHolder from '@/components/layout/AuthViewHolder.vue';
import CodeInput from '@/components/controls/CodeInput.vue';
import useMessages from '@/composables/use-messages';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import { useRouter } from 'vue-router';
import { isPowerPoint, isPowerPointModal } from '@/office-checker';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import { closePowerPointDialog, openPowerPointDialog } from '@/utils/powerpoint-helper';

export default defineComponent({
  name: 'RegisterEmailVerificationView',
  methods: {
    isPowerPoint() {
      return isPowerPoint
    }
  },
  components: {
    DefaultButton,
    VerticalStack,
    CodeInput,
    AuthViewHolder,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const code = ref('');
    const resendTimer = ref(0);
    const resendDisabled = computed(() => resendTimer.value > 0);
    const resendLabel = computed(() => {
      if (resendTimer.value > 0) {
        return t('screens.registration.emailVerification.buttons.resendSeconds', { seconds: resendTimer.value });
      }
      return t('screens.registration.emailVerification.buttons.resend');
    });

    const { setErrorMessage, setSuccessMessage } = useMessages();

    const onceSent = ref(false);

    let frameId: number | null = null;

    const decrementTimer = (endDateTime: number): void => {
      const now: number = new Date().getTime();
      const diffSeconds: number = Math.max(0, (endDateTime - now) / 1000);

      resendTimer.value = Math.round(diffSeconds);

      if (resendTimer.value > 0) {
        frameId = window.requestAnimationFrame(() => decrementTimer(endDateTime));
      } else if (frameId) {
        window.cancelAnimationFrame(frameId);
        frameId = null;
      }
    };

    const startTimer = (date: Date): void => {
      const resendDateTime: number = new Date(date).getTime();

      if (frameId !== null) {
        window.cancelAnimationFrame(frameId);
      }

      decrementTimer(resendDateTime);
    };

    MeService.resendAfter().then((date) => {
      startTimer(date);
    });

    const resendCode = () => {
      store.dispatch('startLoading');
      MeService.resendVerificationEmail()
        .then(async (): Promise<Date> => MeService.resendAfter())
        .then((date: Date) => {
          startTimer(date);
          setSuccessMessage(t('screens.registration.emailVerification.success.resend'));
        })
        .catch(() => {
          setErrorMessage(t('errors.unknown'));
        })
        .finally(() => {
          store.dispatch('stopLoading');
        });
    };

    const verify = () => {
      onceSent.value = true;
      const fullCode = code.value;
      if (fullCode.length < 6) {
        setErrorMessage(t('screens.registration.emailVerification.errors.invalidCode'));
        return;
      }

      store.dispatch('startLoading');

      MeService.verifyEmail(fullCode)
        .then(async () => {
          await store.dispatch('loadUser');
          if (isPowerPointModal) {
            closePowerPointDialog();
          } else {
            await router.push('/');
          }
        })
        .catch((error) => {
          if (isAxiosError(error) && error.response?.status === 401) {
            setErrorMessage(t('screens.registration.emailVerification.errors.invalidCode'));
          } else if (isAxiosError(error) && error.response?.status === 410) {
            setErrorMessage(t('screens.registration.emailVerification.errors.codeExpired'));
          } else {
            setErrorMessage(t('errors.unknown'));
          }
        }).finally(() => {
          store.dispatch('stopLoading');
        });
    };

    const openDialog = () => {
        if (isPowerPoint) {
          openPowerPointDialog({
            url: `https://${window.location.host}/register/emailnotverified`,
            onClosed: () => {
              setTimeout(() => {
                window.location.reload();
              }, 500);
              return true;
            },
          });
        }
    }

    onMounted(() => {
      openDialog();
    });

    return {
      code,
      verify,
      t,
      resendTimer,
      resendDisabled,
      resendLabel,
      resendCode,
      onceSent,
    };
  },
});
</script>
