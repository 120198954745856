<template>
  <auth-view-holder
    :title="$t('screens.login.title')"
  >
    <form @submit.prevent="login">
      <template v-if="showCredentialsLogin">
        <VerticalStack>
          <labeled-default-input
            class="w-full"
            :label="$t('screens.login.email.label')"
            v-model="email"
            type="email"
            :error="onceSent && email === ''" />
          <labeled-default-input
            class="w-full"
            :label="$t('screens.login.password.label')"
            v-model="password"
            type="password"
            :error="onceSent && password === ''" />
        </VerticalStack>
      </template>
      <img v-if="!showCredentialsLogin"
           :src="inOfficeAddin() ? '/icons/powerpoint.svg' : '/icons/microsoft.svg'"
           class="w-20 h-20 mx-auto"
      />
      <default-checkbox
        class="pt-6"
        v-if="!inOfficeAddin()"
        v-model="rememberMe"
        :label="$t('screens.login.stayLoggedIn.label')"
        size="large"
      />
      <default-button
        v-if="showCredentialsLogin"
        actionType="submit"
        :label="$t('screens.login.buttons.login')"
        class="w-full mt-6"
        auto-loading-state="global"
      />
      <template v-if="!inOfficeAddin()">
        <default-button
          :label="$t('screens.login.buttons.loginWithMicrosoft')"
          class="w-full mt-6"
          auto-loading-state="global"
          @click="loginWithMicrosoft"
        />
      </template>
      <template v-else>
        <default-button
          :label="$t('screens.login.buttons.loginWithPowerPoint')"
          class="w-full mt-6"
          auto-loading-state="global"
          @click="loginWithPowerPoint"
        />
        <p
          class="pt-4 text-foreground-light text-center"
        >
          {{ $t('screens.login.labels.powerPointLoginHint') }}
        </p>
        <default-button
          type="link"
          class="w-full mt-6"
          @click="loginWithMicrosoft"
        >
          {{ $t('screens.login.buttons.loginWithAnotherMicrosoftAccount') }}
        </default-button>
      </template>
      <VerticalStack class="w-full text-center mt-6" alignment="center">
        <default-button
          type="link"
          v-if="!showCredentialsLogin"
          @click="showCredentialsLogin = true"
        >
          {{ $t('screens.login.buttons.useCredentials') }}
        </default-button>
        <default-button v-if="inOfficeAddin()" type="link" @click="openRegisterInBrowser(false)" auto-loading-state="global">
          {{ $t('screens.login.links.register') }}
        </default-button>
        <default-button v-else to-route="/register" auto-loading-state="global">
          {{ $t('screens.login.links.register') }}
        </default-button>
        <template v-if="showCredentialsLogin">
          <default-button v-if="inOfficeAddin()" type="link" @click="openForgotPasswordInBrowser" auto-loading-state="global">
            {{ $t('screens.login.links.forgotPassword') }}
          </default-button>
          <default-button v-else to-route="/forgot-password" auto-loading-state="global">
            {{ $t('screens.login.links.forgotPassword') }}
          </default-button>
        </template>
      </VerticalStack>
    </form>
  </auth-view-holder>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import AuthService from '@/services/AuthService';
import { isAxiosError } from 'axios';
import AuthViewHolder from '@/components/layout/AuthViewHolder.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import useMessages from '@/composables/use-messages';
import { useRouter } from 'vue-router';
import { inOfficeAddin, isPowerPoint } from '@/office-checker';
import { isAxiosErrorWithCode } from '@/utils/axios-error';
import SimpleModal from '@/components/layout/SimpleModal.vue';
import useAlert from '@/composables/use-alert';
import { AlertButtonStyle } from '@/types/AlertInfo';
import DefaultCheckbox from '@/components/controls/DefaultCheckbox.vue';
import { openExternalUrl, openPowerPointDialog } from '@/utils/powerpoint-helper';

export default defineComponent({
  components: {
    DefaultCheckbox,
    SimpleModal,
    DefaultButton,
    AuthViewHolder,
    VerticalStack,
  },
  setup() {
    const email = ref('');
    const password = ref('');
    const rememberMe = ref(true);
    const router = useRouter();

    const showCredentialsLogin = ref(false);

    const store = useStore();
    const { t } = useI18n();

    const onceSent = ref(false);

    const { setErrorMessage, setSuccessMessage } = useMessages();
    const { showAlert, createAlertButton } = useAlert();

    const login = () => {
      onceSent.value = true;
      if (email.value === '' || password.value === '') {
        setErrorMessage(t('screens.login.errors.emptyFields'));
        return;
      }

      store.dispatch('startLoading');

      AuthService.login(email.value, password.value, rememberMe.value, router)
        .catch((error) => {
          if (isAxiosErrorWithCode(error, 401)) {
            setErrorMessage(t('screens.login.errors.loginFailed'));
          } else if (isAxiosError(error) && error.response?.status === 403) {
            setErrorMessage(t('screens.login.errors.licenseError'));
          } else {
            setErrorMessage(t('errors.unknown'));
          }
        })
        .finally(() => {
          store.dispatch('stopLoading');
        });
    };

    const loginWithMicrosoft = async () => {
      if (isPowerPoint) {
        openLoginWithMicrosoftInBrowser(false);
        return;
      }
      try {
        await AuthService.startAuthenticationWithMicrosoft('login', true, rememberMe.value);
      } catch (error) {
        setErrorMessage(t('screens.login.errors.microsoftLoginFailed', { error }));
      }
    };

    const loginWithPowerPoint = async () => {
      try {
        await AuthService.startAuthenticationWithPowerPoint(router);
      } catch (error) {
        if (isAxiosErrorWithCode(error, 404)) {
          // showNoPowerPointUserInfo.value = true;
          showAlert({
            message: 'screens.login.noPowerPointUserInfo.info',
            buttons: [
              createAlertButton({
                title: 'screens.login.noPowerPointUserInfo.button',
                action: async () => {
                  openLoginWithMicrosoftInBrowser(false);
                },
              }),
              createAlertButton({
                title: 'screens.login.noPowerPointUserInfo.registerButton',
                type: 'link',
                action: async () => {
                  openLoginWithMicrosoftInBrowser(true);
                },
              }),
              createAlertButton({
                title: 'screens.login.noPowerPointUserInfo.backToLogin',
                type: 'link',
                action: async () => {},
              }),
            ]
          });
        } else {
          setErrorMessage(t('screens.login.errors.microsoftLoginFailed', { error }));
        }
      }
    };

    const openDialogWithUrl = (url: string) => {
      openPowerPointDialog({
        url,
        onClosed: () => {
          setTimeout(() => {
            window.location.reload();
          }, 500);
          return true;
        },
      });
    };

    const openRegisterInBrowser = (register: boolean ) => {
      if (register) {
        openLoginWithMicrosoftInBrowser(true);
      } else {
        openDialogWithUrl(`https://${window.location.host}/register`);
      }
    };

    const openLoginInBrowser = () => {
      openDialogWithUrl(`https://${window.location.host}/login`);
    };

    const openForgotPasswordInBrowser = () => {
      openPowerPointDialog(
        {
          url: `https://${window.location.host}/forgot-password`,
          onMessageReceived: (message) => {
            if (message === 'success') {
              setSuccessMessage(t('screens.forgotPassword.messages.passwordReset'));
            } else {
              setErrorMessage(message);
            }
            return true;
          },
        },
      )
      openDialogWithUrl(`https://${window.location.host}/forgot-password`);
    };

    const openLoginWithMicrosoftInBrowser = (register: boolean) => {
      openPowerPointDialog({
        url: `https://${window.location.host}/login/powerpoint-modal${register ? '?reason=signup' : ''}`,
        onMessageReceived: (message) => {
          if (message === 'success') {
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            setErrorMessage(message);
          }
          return true;
        },
      });
    };

    return {
      email,
      password,
      rememberMe,
      login,
      onceSent,
      loginWithMicrosoft,
      loginWithPowerPoint,
      inOfficeAddin,
      showCredentialsLogin,
      openRegisterInBrowser,
      openForgotPasswordInBrowser,
      openLoginInBrowser,
    };
  },
});
</script>
