
const selectedSlide = async (
  onCurrentSlide: (slide: PowerPoint.Slide) => Promise<Boolean>,
  properties?: string[],
): Promise<Boolean> => {
  return currentPresentation(async (presentation) => {
    const slideList = presentation.getSelectedSlides();

    if (properties && properties.length > 0) {
      slideList.load(properties.map((property) => `items/${property}`));
    } else {
      slideList.load();
    }
    await presentation.context.sync();
    console.log(slideList.items);

    if (slideList.items.length === 0) {
      return false;
    }

    const slide = slideList.items[0];
    if (properties) {
      slide.load(properties);
      await presentation.context.sync();
    }
    console.log(slide.id);
    return await onCurrentSlide(slide);
  }, properties);
};

const currentPresentation = async (
  onCurrentPresentation: (presentation: PowerPoint.Presentation) => Promise<Boolean>,
  properties?: string | string[],
): Promise<Boolean> => {
  return PowerPoint.run(async (context) => {
    try {
      if (properties) {
        if (Array.isArray(properties)) {
          context.presentation.load(properties);
        } else {
          context.presentation.load([properties]);
        }
      } else {
        context.presentation.load();
      }

      await context.sync();

      const presentation = context.presentation;
      return await onCurrentPresentation(presentation);
    } catch (error) {
      console.error('Error in currentPresentation:', error);
      if (error instanceof OfficeExtension.Error) {
        console.log("Error Name: " + error.name);
        console.log("Error Message: " + error.message);
        console.log("Error Debug Info: " + JSON.stringify(error.debugInfo));
      }
      throw error;
    }
  });
};

const selectSlideById = async (slideId: string): Promise<Boolean> => {
  return currentPresentation(async (presentation) => {
    presentation.setSelectedSlides([slideId]);
    await presentation.context.sync();
    return true;
  }, []);
};

const selectedShape = async (
  onCurrentShape: (shape: PowerPoint.Shape) => Promise<Boolean>,
  properties?: string[],
): Promise<Boolean> => {

  return currentPresentation(async (presentation) => {
    const shapeList = presentation.getSelectedShapes();

    if (properties && properties.length > 0) {
      shapeList.load(properties.map((property) => `items/${property}`));
    } else {
      shapeList.load();
    }

    await presentation.context.sync();

    if (shapeList.items.length === 0) {
      return false;
    }

    const shape = shapeList.getItemAt(0);

    if (properties) {
      shape.load(properties);
      await presentation.context.sync();
    }

    return await onCurrentShape(shape);
  }, properties);
}

const openExternalUrl = (url: string, oneTimeToken: string | null = null) => {
  if (oneTimeToken) {
    url = `${url}?ott=${encodeURIComponent(oneTimeToken)}`;
  }
  if (!Office.context.ui || !Office.context.ui.openBrowserWindow) {
    window.open(url, '_blank');
    return;
  }
  Office.context.ui.openBrowserWindow(url);
}

const openPowerPointDialog = ({
  url,
  onMessageReceived = () => true,
  onClosed = () => { },
}: {
  url: string,
  onMessageReceived?: (message: string) => boolean,
  onClosed?: () => void,
}) => {
  if (!Office.context.ui || !Office.context.ui.displayDialogAsync) {
    window.open(url, '_blank');
    return;
  }
  Office.context.ui.displayDialogAsync(url, { height: 80, width: 60 }, (asyncDialog) => {
    const dialog = asyncDialog.value
    dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
      if (isMessage(arg) && onMessageReceived(arg.message)) {
        dialog.close();
        onClosed();
      }
    });
    dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg) => {
      onClosed();
      console.log(arg);
    });
    console.log(dialog);
  });
}

const closePowerPointDialog = () => {
  if (!Office.context.ui || !Office.context.ui.messageParent) {
    return;
  }
  Office.context.ui.messageParent('close');
}

const sendMessageToParent = (message: string) => {
  if (!Office.context.ui || !Office.context.ui.messageParent) {
    return;
  }
  Office.context.ui.messageParent(message);
}

function isMessage(arg: any): arg is { message: string } {
  return arg.message !== undefined;
}

export {
  selectedSlide,
  currentPresentation,
  openExternalUrl,
  selectedShape,
  selectSlideById,
  openPowerPointDialog,
  closePowerPointDialog,
  sendMessageToParent,
}
