<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import MenuDetailView from '@/components/layout/MenuDetailView.vue';
import MenuItem from '@/components/controls/MenuItem.vue';
import StackSpacer from '@/components/layout/StackSpacer.vue';
import SimpleDivider from '@/components/viewelements/SimpleDivider.vue';
import { useStore } from 'vuex';
import SearchInput from '@/components/controls/SearchInput.vue';
import BreadCrumps from '@/components/controls/BreadCrumps.vue';
import TabControl from '@/components/controls/TabControl.vue';
import { useI18n } from 'vue-i18n';
import User from '@/types/User';
import CenteredContent from '@/components/layout/CenteredContent.vue';

export default defineComponent({
  components: {
    CenteredContent,
    TabControl,
    BreadCrumps,
    SearchInput,
    SimpleDivider,
    StackSpacer,
    VerticalStack,
    MenuDetailView,
    MenuItem
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const searchString = ref('');

    const userHasToCreateOrganization = computed(() => {
      return !store.getters.organizationName && (store.getters.user as User)?.allowed('ORGANIZATION_CREATE');
    });

    const isRoot = computed(() => {
      return !route.params.category;
    });

    const tags = computed(() => {
      return store.getters.tags;
    });

    const subviewKey = computed(() => {
      return route.params.category || route.params.slug;
    });

    watch(searchString, (value, oldValue) => {

      if (value === oldValue) {
        return;
      }
      if (value.length > 0) {
        router.push({
          path: `/admin/files/content/${route.params.type}`,
          query: { searchString: value }
        });
      } else {
        router.push(`/admin/files/content/${route.params.type}`);
      }
    });

    onMounted(() => {
      store.dispatch('loadAllTags');
      searchString.value = route.query.searchString as string || '';
    });

    return {
      subviewKey,
      tags,
      searchString,
      isRoot,
      userHasToCreateOrganization,
    };
  },
});
</script>

<template>
  <MenuDetailView v-if="!userHasToCreateOrganization" :content-scrollable="false">
    <template #menu>
      <MenuItem
        to="/admin/files/content/images"
        :exact="false"
        :label="$t('screens.files.menu.corporateImages')"
      />
      <MenuItem
        to="/admin/files/content/icons"
        :exact="false"
        :label="$t('screens.files.menu.staticIcons')"
      />
      <MenuItem
        to="/admin/files/content/animated_icons"
        :exact="false"
        :label="$t('screens.files.menu.animatedIcons')"
      />
      <MenuItem
        to="/admin/files/content/presentations"
        :exact="false"
        :label="$t('screens.files.menu.presentations')"
      />
      <MenuItem
        to="/admin/files/content/slides"
        :exact="false"
        :label="$t('screens.files.menu.slides')"
      />
      <StackSpacer />
      <h3>{{ $t('screens.files.menu.tags') }}</h3>
      <MenuItem
        v-for="tag in tags"
        :to="`/admin/files/tags/${tag.slug}`"
        :exact="false"
        :label="tag.numberOfFiles ? `${tag.name} (${tag.numberOfFiles})` : tag.name"
        icon="/icons/price-tag.svg"
      />
    </template>
    <template #content>
      <vertical-stack class="h-full overflow-hidden">
        <SearchInput
          class="w-96 mt-4"
          v-model="searchString"
          v-if="isRoot"
        />
        <router-view :key="subviewKey"/>
      </vertical-stack>
    </template>
  </MenuDetailView>
  <CenteredContent v-else class="w-full">
    <VerticalStack alignment="center" class="w-1/3 p-8">
      <h1>{{ $t('screens.files.noOrganizationInfo.title') }}</h1>
      <p>{{ $t('screens.files.noOrganizationInfo.message') }}</p>
    </VerticalStack>
  </CenteredContent>
</template>
