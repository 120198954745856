<template>
  <HorizontalStack
    alignment="top"
    :spacing="2"
    class="cursor-pointer"
    @click="toggle"
  >
    <input
      type="checkbox"
      v-model="checked"
      @click.stop
      class="cursor-pointer"
      :class="{ 'size-5 grow-0 shrink-0': size === 'large',}"
    />
    <VerticalStack
      :spacing="null"
    >
      <span>{{ label }}</span>
      <span
        v-if="info"
        class="text-sm text-foreground-light"
      >{{ info }}</span>
    </VerticalStack>
  </HorizontalStack>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';

export default defineComponent({
  components: {VerticalStack},
  props: {
    label: String,
    info: {
      type: String,
      required: false,
    },
    modelValue: Boolean,
    size: {
      type: String as PropType<'small' | 'large'>,
      default: 'small',
    },
  },
  setup(props, { emit }) {
    const checked = ref(props.modelValue);

    checked.value = props.modelValue;

    const toggle = () => {
      checked.value = !checked.value;
    };

    watch(() => checked.value, (newVal) => {
      emit('update:modelValue', newVal);
    });

    watch(() => props.modelValue, (newVal) => {
      checked.value = newVal;
    });

    return {
      checked,
      toggle,
      label: props.label
    };
  },
});
</script>
