import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized, createMemoryHistory,
} from 'vue-router';
import LoginView from '@/views/general/LoginView.vue';
import NotFound from '@/views/NotFoundView.vue';
import { i18n } from '@/i18n/i18n-setup';
import registerRoutes from '@/router/register-routes';
import addInRoutes from '@/router/addin-routes';
import EmptyView from '@/components/PlaceholderView.vue';
import LicenseInvalidLoginErrorView from '@/views/general/LicenseInvalidLoginErrorView.vue';
import store from '@/store/store';
import User from '@/types/User';
import adminRoutes from '@/router/admin-routes';
import licenseRoutes from '@/router/su-license-routes';
import organzationRoutes from '@/router/su-organization-routes';
import NotAllowed from '@/views/NotAllowedView.vue';
import { inOfficeAddin, isPowerPoint, isPowerPointModal } from '@/office-checker';
import LoadingView from '@/views/general/LoadingView.vue';
import checkoutRoutes from '@/router/checkout-routes';
import { LocalSettingsService } from '@/services/LocalSettingsService';
import { openPowerPointDialog } from '@/utils/powerpoint-helper';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: EmptyView,
    redirect: inOfficeAddin() ? '/addin' : '/admin',
    children: [],
    meta: {
      requiresAuth: true,
      titleKey: 'screens.home.title',
    },
  },
  {
    path: '/no/valid/license',
    name: 'noValidLicense',
    component: LicenseInvalidLoginErrorView
  },
  {
    path: '/oauth2/azure',
    name: 'oauthAzure',
    component: () => import('@/views/general/AzureOAuthHandlerView.vue'),
    meta: {
      requiresAuth: false,
      titleKey: 'screens.oauth.azure.title',
    },
  },
  {
    path: '/azure/admin-consent',
    name: 'AzureAdminConsent',
    component: () => import('@/views/general/AzureAdminConsentHandlerView.vue'),
    meta: {
      requiresAuth: false,
      titleKey: 'screens.oauth.azure.title',
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/MainContainer.vue'),
    meta: {
      requiresAuth: true,
      titleKey: 'screens.profile.title',
    },
    redirect: '/admin/files',
    children: [
      ...adminRoutes,
    ],
  },
  {
    path: '/su',
    name: 'super-user',
    component: () => import('@/views/superuser/MainContainer.vue'),
    meta: {
      requiresAuth: true,
      titleKey: 'screens.profile.title',
    },
    redirect: '/su/licenses',
    children: [
      ...licenseRoutes,
      ...organzationRoutes,
      {
        path: 'app-states',
        name: 'su-app-states',
        component: () => import('@/views/superuser/appStates/AppStatesView.vue'),
      }
    ],
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoadingView,
    meta: {
      requiresAuth: true,
      titleKey: 'screens.home.title',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false,
      titleKey: 'screens.login.title',
    },
  },
  {
    path: '/login/powerpoint-modal',
    name: 'login-powerpoint-modal',
    component: () => import('@/views/general/StartModalMicrosoftSSOView.vue'),
    meta: {
      requiresAuth: false,
      titleKey: 'screens.login.title',
    },
  },
  {
    path: '/login/2fa/:type',
    name: 'twoFA',
    component: () => import('@/views/general/TwoFAView.vue'),
    meta: {
      requiresAuth: false,
      titleKey: 'screens.twofa.title',
    },
  },
  ...checkoutRoutes,
  {
    path: '/payment_checkout_result',
    name: 'paymentCheckoutResult',
    component: () => import('@/views/payment/PaymentResultView.vue'),
    meta: {
      requiresAuth: true,
      titleKey: 'screens.checkout.result.title',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('@/views/general/ForgotPasswordView.vue'),
    meta: {
      requiresAuth: false,
      titleKey: 'screens.forgotPassword.title',
    },
  },
  ...registerRoutes,
  ...addInRoutes,
  {
    path: '/not-allowed',
    name: 'notAllowed',
    component: NotAllowed,
    meta: {
      requiresAuth: false,
      titleKey: 'screens.notAllowed.title',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: {
      requiresAuth: false,
      titleKey: 'screens.notFound.title',
    },
  },
];

const setTitle = (to: RouteLocationNormalized) => {
  const defaultTitle = 'slideroom';
  const { titleKey } = to.meta;
  document.title = titleKey && typeof titleKey === 'string'
    ? `${defaultTitle} - ${i18n.global.t(titleKey).toString()}`
    : defaultTitle;
};

const redirectToLoginIfUnauthenticated = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to.meta.requiresAuth && !store.state.user) {
    sessionStorage.setItem('lastAttemptedRoute', to.path);
    next({ name: 'login', query: to.query });
    return true;
  }
  return false;
};

const redirectToEmailNotVerifiedIfNecessary = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (store.state.user && !(store.state.user as User).emailValidated && to.name !== 'emailNotVerified') {
    // if (isPowerPoint) {
    //   openPowerPointDialog({
    //     url: `https://${window.location.host}/register/emailnotverified`,
    //     onClosed: () => {
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 500);
    //       return true;
    //     },
    //   });
    // }
    next({ name: 'emailNotVerified', query: to.query });
    return true;
  }
  return false;
};

const redirectToOrganizationCreateIfNecessary = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  // we return here for now as long as we do not have a propper solution for the organization creation

  // if (LocalSettingsService.getOrganizationCreationSkipped()) {
  //   return false;
  // }
  // if (isPowerPoint) {
  //   return false;
  // }
  // if (to.name === 'create-org' || to.name === 'emailNotVerified') {
  //   return false;
  // }
  // if (store.state.user && (store.state.user as User).organizationSlug === null) {
  //   next({ name: 'create-org', query: to.query });
  //   return true;
  // }
  return false;
}

const redirectToLastAttemptedRouteIfPresent = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const lastAttemptedRoute = sessionStorage.getItem('lastAttemptedRoute');
  if (store.state.user && lastAttemptedRoute && to.name !== 'emailNotVerified') {
    sessionStorage.removeItem('lastAttemptedRoute');
    next({ path: lastAttemptedRoute, query: to.query });
    return true;
  }
  return false;
};

const redirectIfNotPermitted = (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (store.state.user && to.meta.permissions) {
    // Ensure permissionsArray is always treated as an array
    const permissionsArray = Array.isArray(to.meta.permissions)
      ? to.meta.permissions
      : [to.meta.permissions];
    const permitted = permissionsArray.some((permission) => (store.state.user as unknown as User)
      .allowed(permission));
    if (!permitted) {
      if (to.meta.alternative) {
        next({ path: (to.meta.alternative as string), query: to.query });
      } else {
        next({ name: 'notAllowed', query: to.query });
      }
      return true;
    }
    return false;
  }
  return false;
};

function addNavigationGuards(router: ReturnType<typeof createRouter>) {
  router.beforeEach((to, from, next) => {
    if (from.path === '/no/valid/license' && to.path !== '/login') {
      return;
    }
    setTitle(to);

    if (!store.state.userLoaded) {
      next();
      return;
    }

    if (redirectToLoginIfUnauthenticated(to, next)) return;
    if (redirectToEmailNotVerifiedIfNecessary(to, next)) return;
    if (redirectToOrganizationCreateIfNecessary(to, next)) return;
    if (redirectToLastAttemptedRouteIfPresent(to, next)) return;
    if (redirectIfNotPermitted(to, next)) return;

    next();
  });
}

const createWebRouter = async () => {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
  const initialPath = window.location.pathname + window.location.search + window.location.hash;
  await router.replace(initialPath);
  addNavigationGuards(router);
  return router;
};

const createAddinRouter = async () => {
  const router = createRouter({
    history: createMemoryHistory(),
    routes,
  });
  const initialPath = window.location.pathname + window.location.search + window.location.hash;
  await router.replace(initialPath);
  addNavigationGuards(router);
  return router;
};

export {
  createWebRouter,
  createAddinRouter,
};
